import service from "@/api/service";
import { GetResponse } from "@/api/shop-point/response";
import { SearchRequest } from "@/api/shop-point/request";
import { SearchResponse } from "@/api/shop-point/response";

/**
 * ポイントカード店舗一覧APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-point-shop", searchRequest);
  return response.data as SearchResponse;
}

/**
 * ポイントカード店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-point-shop");
  return response.data as GetResponse;
}
