import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/shop-point/request";
import { SearchItem } from "@/api/shop-point/response";
import ShopPointSearch from "@/store/shop-point/search";
import ShopPointGet from "@/store/shop-point/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDialogDelete }
})
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ポイント";
  headingSub = "Points";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "ポイント", disabled: true },
    { text: "店舗一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    { text: "", value: "space", sortable: false },
    {
      label: "ポイントカード一覧",
      text: "",
      value: "action",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return ShopPointSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return ShopPointSearch.getTotalCount;
  }

  get shopItems() {
    return ShopPointGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await ShopPointSearch.restore(this.inputOptions as SearchRequest);
    const request = ShopPointSearch.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopPointSearch.clearResponse();
    await ShopPointGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    this.tableOptions.page = 1;//検索後、1ページ目へ移動
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async actionClickCallback(item: SearchItem) {
    await this.$router.push({
      name: "points-list",
      params: { shopId: String(item.id) },
      query: { shopName: item.name }
    });
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (ShopPointGet.isSuccess) {
      return true;
    }

    await ShopPointGet.get();
    if (!ShopPointGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopPointGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return ShopPointGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    let isSuccess = await this.fetchShopList();
    if (!isSuccess) {
      return;
    }

    let request = this.createRequest();
    await ShopPointSearch.search(request);
    if (!ShopPointSearch.isSuccess) {
      await Flash.setErrorNow({
        message: ShopPointSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }

    return request;
  }
}
